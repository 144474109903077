/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-08 16:52
 * @description：index
 * @update: 2022-11-08 16:52
 */
// 小票打印机对齐方向枚举
export enum alignEnum {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export interface IPrint {
  // 类型
  type: string;
  // 小票对齐方式
  align?: alignEnum;
  // 内容
  data: string | number;
  // 小票打印机二维码宽度
  width?: number;
  // 标签打印机x
  x?: number;
  // 标签打印机y
  y?: number;
  // 字体
  size?: any;
}
