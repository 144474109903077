/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-03 14:59
 * @description：index
 * @update: 2022-11-03 14:59
 */
// 重量单位
export enum EWeightUnit {
  // KG
  KG = 0,
  // 斤
  catty = 1
}
// 自动打印
export enum EAutoPrint {
  ON = 1,
  OFF = 0
}
