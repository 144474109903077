/**
 * @name: weightUtil
 * @author: itmobai
 * @date: 2022-11-08 14:27
 * @description：weightUtil
 * @update: 2022-11-08 14:27
 */

/**
 *
 * @param num
 * @param n
 * @returns
 */
const prefixZero = (num: any, n: any) => {
  return (Array(n).join("0") + num).slice(-n);
};

/**
 * 转换金额
 * @param {*} value 金额
 * @returns
 */
const moneyFormats = (value: any) => {
  const a = Number(value);
  const b = a.toLocaleString("zh", { style: "currency", currency: "CNY" });
  return b.substring(1);
};

/**
 * 填充数据
 * @param {*} num
 * @param {*} len
 * @returns
 */
const autoFixSpace = (num: any, len = 8) => {
  return num.toString().padStart(len, " ");
};

export default {
  moneyFormats,
  autoFixSpace,
  prefixZero,
};
