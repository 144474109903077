/**
 * @name: regex
 * @author: itmobai
 * @date: 2022-11-07 13:48
 * @description：regex
 * @update: 2022-11-07 13:48
 */

/**
 * 金额校验
 * @param value
 */
const priceRegex = (value: string): boolean => {
  const reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
  if (!reg.test(value)) {
    return false;
  }
  return true;
};

export default {
  priceRegex
}
